var LazyIframe = (function(){
	function LazyIframe (alvo){
		this.element = alvo;
		this.frame = alvo.children[0].innerHTML;
		this.verifica = function (a,t){
			var a = a || this.element;
			var t = t || this.frame;

			if(!a.getAttribute('loaded')){

				if(a.getBoundingClientRect().top < window.innerHeight + 200){
					a.innerHTML = t;
					a.setAttribute('loaded', 'true');
				}

			}
		};
		this.j = false;
	}
	

	LazyIframe.prototype.isRect = function() {
		var e = this;
		var j = this.j;

		e.verifica();

		window.addEventListener('scroll', function(){
			if(j) return;

			setTimeout(function(){
				j = false;
			},100);

			e.verifica();
		});
	};

	return LazyIframe;
})();

var lazyiframes = document.querySelectorAll('lazyiframe');

if(lazyiframes){
	for(var i=0; i<lazyiframes.length; i++){
		lazyiframes[i].atrazaIframe = new LazyIframe(lazyiframes[i]);
		lazyiframes[i].atrazaIframe.isRect();
	}
}