var startSaporra = function (){
  $("#servicos").Cloud9Carousel( {
    buttonLeft: $("#buttons > .left"),
    buttonRight: $("#buttons > .right"),
    autoPlay: 0,
    bringToFront: true,
    onLoaded: function(carousel){
      $('#servicos').addClass('loaded');
      verifyCloud9('#servicos', carousel);
      var wPos =  $(window).scrollTop();
      $(window).scrollTop(wPos+1);

      setTimeout(function(){
        $(window).scrollTop(wPos);
      },1);
    }
  });
}

setTimeout(function(){
  startSaporra();

  $(window).resize(function(){
    startSaporra();
  });
},300);

function verifyCloud9 (alvo, data){
  var midiaSize = 'xs';

  if($(window).width() > 600){
    midiaSize = 'sm';
  }else{
    midiaSize = 'xs';
  }

  switch (midiaSize){
    case 'xs':
      $(alvo).height((data.items[0].fullHeight * 2) + data.items[0].height);
    break;
    case 'sm':
      $(alvo).height(data.items[0].fullHeight * 1.75);
    break;
  }
}
